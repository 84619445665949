import React, { Component, Fragment, useState } from "react";
import VehicleInformation from "./VehicleInformation";
import VehicleTab from "./VehicleTab";
import _ from "lodash";
import "./vehicle.scss";
import VehicleNotes from "./VehicleNotes";
import StatusLog from "../Vehicle/StatusLog/index";
import InformationTable from "../InformationTable";

const VehicleDetailsLower = ({
  vehicleInfo,
  city,
  currentUser,
  refetch,
  teslaVehicleStatus,
  vehicleTimeZone,
}) => {
  const [display, setDisplay] = useState("information");
  const [newNote, setNewNote] = useState();
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState(null);

  const toggleDisplay = (display) => setDisplay(display);
  const notesOnChange = (text) => setNewNote(text);
  const toggleAddNote = () => setAddNote(!addNote);
  const editChange = (text) => setEditNote(text);

  const renderTap = (display) => {
    switch (display) {
      default:
      case "information":
        return (
          <VehicleInformation
            vehicleInfo={vehicleInfo}
            currentUser={currentUser}
            city={city}
            refetch={refetch}
          />
        );
      case "bookings":
        // return <StatusLog statusLog={vehicleInfo.statusLog} fullSize />;
        return (
          <InformationTable
            tableName={"timeline_entries"}
            vehicleInfo={vehicleInfo}
            hasTabs={false}
            background={"white"}
            rowComponentType={"timeline"}
            startTerms={"Search by User Name and Category"}
            item={"timeline entries"}
            refetch={refetch}
            id={vehicleInfo?.id}
            vehicleSpecific={true}
            vehicleTimeZone={vehicleTimeZone}
          />
        );
      case "onlyBookings":
        return (
          <StatusLog
            statusLog={vehicleInfo.statusLog}
            fullSize
            timezone={vehicleInfo.city?.timeZone}
          />
        );
      case "notes":
        return (
          <VehicleNotes
            notes={vehicleInfo.notes}
            onChange={notesOnChange}
            newNote={newNote}
            addNote={addNote}
            closeNote={toggleAddNote}
            vehicleId={vehicleInfo.id}
            refetch={refetch}
            currentUser={currentUser}
            editChange={editChange}
            editedNote={editNote}
          />
        );
    }
  };
  return (
    <Fragment>
      <div className="vehicle-details-tab">
        <div className="vehicle-col" style={{ marginBottom: "1em" }}>
          <div className="vehicle-details-tab">
            <div className="vehicle-details-tab">
              <VehicleTab
                name="Information"
                type="information"
                toggle={toggleDisplay}
                display={display}
              />
              <VehicleTab
                name="Timeline"
                type="bookings"
                toggle={toggleDisplay}
                display={display}
              />
              <VehicleTab
                name="Notes"
                type="notes"
                toggle={toggleDisplay}
                display={display}
              />
              <VehicleTab
                name="Log"
                type="onlyBookings"
                toggle={toggleDisplay}
                display={display}
              />
            </div>
          </div>
          {renderTap(display)}
        </div>
      </div>
    </Fragment>
  );
};

export default VehicleDetailsLower;
